import React, { useState, useEffect } from 'react';
import style from './highlightRow.module.scss';
import { PrimaryAnchorButton } from './buttons';
import ResizeObserver from 'resize-observer-polyfill';
import { PropComponent } from '../helpers/dataHelpers';

export type HighlightRowProps = {
    title: string,
    description: string,
    image: { fluid?: {src?: string}},
    buttonText: string,
    buttonLink: string
};

const HighlightRow: PropComponent<HighlightRowProps> = ({ title, description, image, buttonText, buttonLink }) =>  {

    const padding = '48px';

    let [boxADetails, setBoxADetails] = useState({
        height: `0px`,
        width: `0px`,
        top: `0px`,
        left: `0px`
    });
    let [boxBDetails, setBoxBDetails] = useState({
        height: `0px`,
        width: `0px`,
        top: `0px`,
        left: `0px`
    });

    let calcBoxDetails = () => {
        let content = document.querySelector('#rowInner') as HTMLElement;
        if (content) {
            setBoxADetails({
                height: `${content.offsetHeight}px`,
                width: `${content.offsetWidth}px`,
                top: `${content.offsetTop}px`,
                left: `${content.offsetLeft}px`,
            })
            setBoxBDetails({
                height: `${content.offsetHeight}px`,
                width: `${content.offsetWidth}px`,
                top: `${content.offsetTop}px`,
                left: `${content.offsetLeft}px`,
            })
        }
    };


    useEffect(() => {
        let boxA = document.querySelector('#rowBoxA') as HTMLElement;
        if (boxA && boxADetails) {
            boxA.style.height = `calc(${boxADetails.height} * 3)`;
            boxA.style.width = `calc(${boxADetails.width} + 24px)`;
            boxA.style.top = `calc(${boxADetails.top} - (${boxADetails.height}) - ${padding})`;
            boxA.style.left = `calc(${boxADetails.left} - ${padding})`;
        }
        let boxB = document.querySelector('#rowBoxB') as HTMLElement;
        if (boxB && boxBDetails) {
            boxB.style.height = `calc(${boxBDetails.height} * 3)`;
            boxB.style.width = boxBDetails.width;
            boxB.style.top = `calc(${boxBDetails.top} - (${boxBDetails.height}) - ${padding})`;
            boxB.style.left = `calc(${boxBDetails.left} - ${padding})`;
        }
    }, [boxADetails, boxBDetails]);

    useEffect(() => {
        let res = new ResizeObserver(calcBoxDetails);
        const row =document.querySelector('#row');
        row ? res.observe(row) : null;
        setTimeout(() => calcBoxDetails(), 50)
    }, []);

    return (<div id="row" className={style.row} style={!!image ? {'backgroundImage': `url(${image?.fluid?.src})`} : {}}>
        <div id="rowBoxA" className={style.rowBoxA}></div>
        <div id="rowBoxB" className={style.rowBoxB}></div>
        <div id="rowInner" className={style.rowContent}>
            <div className={style.rowTitle}>{title}</div>
            <p className={style.rowDescription}>{description}</p>
            <div className={style.rowButton}>
                <PrimaryAnchorButton href={buttonLink}>{buttonText}</PrimaryAnchorButton>
            </div>
        </div>
    </div>);
}

export default HighlightRow;
