import React from 'react';
import Style from './contract-vehicles.module.scss';
import PageWithHeader from '../components/pageWithHeader';
import HighlightRow from '../components/highlightRow';
import { graphql, useStaticQuery, Link } from 'gatsby';
// @ts-ignore
import navy from '../../static/images/navy.png';
// @ts-ignore
import cms from '../../static/images/cms.png';
// @ts-ignore
import nrc from '../../static/images/nrc.png';
import classNames from '../helpers/classNames';
import { DataComponent } from '../helpers/dataHelpers';

const ContractVehicle = () => {
    var data = useStaticQuery<GatsbyTypes.ContractVehicleQuery>(graphql`
    query ContractVehicle {
        contentfulHomeContent(current: {eq: true}) {
            contractVehicleHighlightImage {
                fluid(quality: 100, cropFocus: CENTER) {
                    src
                }
            }
        }
    }`);
    return <ContractVehicleInner data={data}></ContractVehicleInner>
 
}
export default ContractVehicle;

const ContractVehicleInner: DataComponent<GatsbyTypes.ContractVehicleQuery> = ({data}) => {
    var heighlightImage = data.contentfulHomeContent?.contractVehicleHighlightImage;
    return (
        <PageWithHeader
            title="Contract Vehicles"
            description="Contract Vehicles available to Centeva">
            <article className={Style.contractVehiclePage}>
                <div className={Style.pageHeader}>
                    <h2>Centeva’s vast portfolio of government contract vehicles affords us the ability to provide innovative professional and technical solutions and services across a broad range of markets. </h2>
                    <p>Centeva’s strong capabilities within GSA schedules, GWACS and agency-specific contract vehicles enables our customers to procure professional services and technology solutions tailored to their mission-critical requirements. Whether your organization is federal, commercial, or global, Centeva maintains a wide-variety of contract vehicle options to best service your evolving needs.  </p>
                </div>
                <div className={Style.contract}>
                    <div className={Style.contractImage}><img src={cms}/></div>
                    <div className={Style.contractBody}>
                        <h3>CMS SPARC</h3>
                        <h4>Contract Holder HHSM-500-2017-000141</h4>
                        <p>SPARC is a multiple award, Indefinite Delivery/Indefinite Quantity (IDIQ), performance-based contract. It is available to all components within Centers for Medicare & Medicaid Services (CMS) and all Health and Human Services (HHS) Operating Divisions to procure IT System development services, from end to end.</p>
                        <div>
                            <ul>
                                <li>$25 Billion Ceiling</li>
                                <li>NO ADMINISTATIVE FEE</li>
                                <li>Shortened procurement lead time</li>
                                <li>Access to high-quality industry partners</li>
                            </ul>
                            <ul>
                                <li>Partners offer agile, waterfall, and hybrid methodology</li>
                                <li>Small business set-aside</li>
                                <li>Socioeconomic credit potential</li>
                                <li>Ability to sole source to 8(a) partners for task order under $4 million</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classNames(Style.contract)}>
                <div className={Style.contractImage}><img src={navy}/></div>
                    <div className={Style.contractBody}>
                        <h3>NAVY SeaPort-E</h3>
                        <h4>Contract Holder N00178-15-D8125; Zone: 6</h4>
                        <p>SeaPort-e® is the Navy’s electronic platform for acquiring support services in 22 functional areas including Engineering, Financial Management, and Program Management. The Navy Systems Commands (NAVSEA, NAVAIR, SPAWAR, NAVFAC, and NAVSUP), the Office of Naval Research, Military Sealift Command, and the United States Marine Corps compete their service requirements amongst 2400+ SeaPort-e ® IDIQ multiple award contract holders.</p>
                        <ul>
                            <li>All task orders are competitively solicited, awarded and managed using the SeaPort-e® platform.</li>
                            <li>SeaPort-e® provides an efficient and effective means of contracting for professional support services and enhancing small business participation.</li>
                        </ul>
                    </div>
                </div>
                <HighlightRow
                    title="GSA SCHEDULE CONTRACTS"
                    description="We have dependably delivered products, services, and solutions via our GSA Schedule contracts to our Government customers."
                    image={heighlightImage}
                    buttonText="learn more"
                    buttonLink="../gsa-scheduled-contracts"
                ></HighlightRow>
                <div className={Style.contract}>
                    <div className={Style.contractImage}><img src={nrc}/></div>
                    <div className={Style.contractBody}>
                        <h3>NRC MOMS</h3>
                        <h4>Contract Holder NRC-HQ-10-14-E-0001</h4>
                        <p>The objective of “Maintenance, Operation, and Modernization Support of U.S. Nuclear Regulatory Commission Application Systems and Environment” (also referred to as “MOM”) is to procure continuous maintenance and operational Information Technology (IT) support services for current and future NRC automated computer systems.</p>
                        <ul>
                            <li>Functional Area 1: Centralized Environment Support</li>
                            <li>Functional Area 2: O&M of Legacy Systems</li>
                            <li>Functional Area 3: Requirements and Design</li>
                            <li>Functional Area 4: Legacy Modernization and New Software Application Development</li>
                            <li>Functional Area 5: O&M of Modernized Systems</li>
                        </ul>
                    </div>
                </div>
            </article>
            <div className={Style.articleFooter}>
                <h3>Powerful Solution. Effective Support.</h3>
                <Link to="/contact/"><button>Contact us</button></Link>
            </div>
        </PageWithHeader>
    );
}